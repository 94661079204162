<template>
  <form
    class="contact-form"
    :class="{ tried }"
    id="contact-form"
    action="#contact-form"
    @submit="onSubmit"
  >
    <input
      type="text"
      :placeholder="`${$lang('contact_form.full_name', true)} *`"
      v-model="form.full_name"
      required
      name="your-name"
    />
    <input
      type="text"
      :placeholder="`${$lang('contact_form.email', true)} *`"
      v-model="form.email"
      required
      name="your-email"
    />
    <input
      type="text"
      :placeholder="`${$lang('contact_form.phone', true)} *`"
      v-model="form.phone"
      required
      name="phone"
    />
    <Select
      v-model="form.country"
      :label="`${$lang('contact_form.country', true)} *`"
      :options="countries"
      name="country"
    />
    <Select
      v-model="form.nationality"
      :label="`${$lang('contact_form.nationality', true)} *`"
      :options="nationalities"
      name="nationality"
    />

    <div class="ml-4 mb-3">
      <p class="mb-2">{{ $lang("contact_form.campus", true) }}</p>

      <div class="d-flex gap-4">
        <Radio
          label="Barcelona"
          inputValue="Barcelona"
          name="campus"
          v-model="form.campus"
          :class="`checkbox-${theme}`"
        />
        <Radio
          label="Madrid"
          inputValue="Madrid"
          name="campus"
          v-model="form.campus"
          :class="`checkbox-${theme}`"
        />
      </div>
    </div>

    <textarea
      :placeholder="$lang('contact_form.message', true)"
      rows="4"
      v-model="form.message"
      name="message"
    />

    <Checkbox
      class="ml-4"
      :label="$lang('contact_form.terms', true)"
      v-model="form.terms"
      name="terms-accepted"
      :isInvalid="terms_invalid"
      :class="`checkbox-${theme}`"
    />

    <button
      type="submit"
      @click="tried = true"
      class="btn mt-5 ml-auto px-6"
      :class="`btn-${theme}`"
    >
      {{ $lang("contact_form.button", true) }}
    </button>
  </form>
</template>

<script>
import countries_es from "@/assets/json/countries-es.json";
import countries_en from "@/assets/json/countries-en.json";
import Checkbox from "@/components/Forms/Checkbox.vue";
import Radio from "@/components/Forms/Radio.vue";
import Select from "@/components/Forms/Select.vue";
import axios from "axios";

export default {
  props: {
    theme: {
      type: String,
      default: "secondary",
    },
  },
  components: {
    Checkbox,
    Radio,
    Select,
  },
  data: () => ({
    form: {
      full_name: null,
      email: null,
      phone: null,
      country: null,
      nationality: null,
      campus: "Barcelona",
      message: null,
      terms: false,
    },
    tried: false,
    terms_invalid: false,
    countries_en,
    countries_es,
  }),
  computed: {
    countries() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.name,
        label: x.name,
      }));
    },
    nationalities() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.nationality,
        label: x.nationality,
      }));
    },
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.isValid()) return;

      axios.get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
        params: {
          "your-name": this.form.full_name,
          "your-email": this.form.email,
          phone: this.form.phone,
          country: this.form.country,
          nationality: this.form.nationality,
          campus: this.form.campus,
          message: this.form.message,
          token: "wv4b9a2302j1i2pc1195np1469422eryu",
        },
      });

      this.clearForm();

      this.formSuccessMessage();
    },
    isValid() {
      if (!this.form.terms) {
        this.$toast.success(this.$lang("contact_form.check_terms_error", true));
        this.terms_invalid = true;
        return false;
      }

      this.terms_invalid = false;
      return true;
    },
    clearForm() {
      this.tried = false;
      this.terms_invalid = false;
      this.form = {
        full_name: null,
        email: null,
        phone: null,
        country: null,
        nationality: null,
        campus: "Barcelona",
        message: null,
        terms: true,
      };
    },
    formSuccessMessage() {
      this.$toast.success(this.$lang("contact_form.success_msg", true));
    },
  },
};
</script>
